import config from '@configs/index'
import { ICategory } from '@features/category/query'
import { setSeoData } from '@features/seo/slice'
import { PageDataArgs, pageApiService, seoApiService } from '@foundation/apis/seo/seo.ssr.service'
import { SEO_PAGE_TYPES } from '@foundation/hooks/useSeoComponent'
import SEO from '@foundation/seo/SEO'
import { ICommerceHclPage, IPageStaticLanding } from '@typesApp/cms'
import { ISeo, SeoResponse } from '@typesApp/seo'
import { IPlpState } from '@utils/Plp/PlpReducer'
import { fromServerToClientProduct } from '@utils/fromServerToClientEntity'
import { isEmpty } from '@utils/helpers'

import { NOT_FOUND } from '@constants/routes'
import { STORES } from '@foundation/constants/site'
import { IPageBaseProps, dispatchCommonComponents, fetchData, getLocale } from '@foundation/dataFetch'
import { parseCookies } from '@utils/Cookies'
import { getPlpProps } from '@utils/Plp/SSR'
import { serverSidePropsWrapper } from '@utils/SSR/wrapperGetServerProps'
import { redirectTo } from '@utils/common'
import { PAGE_TYPES, getHeaders, setHeaders } from '@utils/httpHeadersUtils'
import { GetServerSideProps, GetServerSidePropsContext } from 'next/types'
import { useDispatch } from 'react-redux'
import { default as AppBase } from '../../App'
import { logger } from '../../logging'
import { getServerSideStoreInfo } from '../../services/ssr'
import { getLocalesDomainsConfig, getServerLocale, replaceLocaleInUrl } from '@utils/locale'
import domainsList from '@configuration/domains/domains-list.json'
import localDomainsList from '@configuration/domains/local-domains-list.json'
import { NotFound } from '@pages_views/NotFound'
import { getHostnameUrls } from '@utils/getHostnameUrls'
import { generateAlternateUrlMapping } from '@utils/seo.util'

interface IPage extends IPageBaseProps {
  seoData: SeoResponse<ISeo>
  seoAltUrl: Record<string, string> | null
  categoryData: ICategory[] | null
  parentCatalogGroupID?: string[] | null
  pdpProduct: ReturnType<typeof fromServerToClientProduct> | null
  host: string
  plpState: IPlpState | null
  plpCommerce: ICommerceHclPage | IPageStaticLanding | null
  pdpCommerce: ICommerceHclPage | null
  cookies: Record<string, string>
  isLocaleDomain: boolean
  locationOrigin: string
}

const Page: React.FC<IPage> = props => {
  const { locale, siteDataRes, header, footer, megaMenu, preFooter, is404, plpCommerce, ...rest } = props
  const dispatch = useDispatch()

  dispatchCommonComponents({ locale, siteDataRes, header, megaMenu, footer, preFooter, rest })
  dispatch(setSeoData(!isEmpty(props.seoData?.contents?.[0]) ? props.seoData?.contents[0] : null))

  return <AppBase>{is404 ? <NotFound pageData={plpCommerce} /> : <SEO {...props} />}</AppBase>
}

export const getServerSideProps: GetServerSideProps<IPage | {}> = serverSidePropsWrapper(
  async (stateManager, context) => {
    let is404 = false
    const { query, req, res } = context
    const { host } = context.req.headers
    const { protocol, hostname } = getHostnameUrls(context.req)
    const { params } = query as { params: Array<string | undefined> }
    const serverUrl = getServerUrl(req)
    const cookies = parseCookies(req)
    const domains = process.env.NODE_ENV === 'development' ? localDomainsList : domainsList
    const { isLocaleDomain, domainLocales, locationOrigin } = getLocalesDomainsConfig(context.req, domains)
    const localeContext = getServerLocale(isLocaleDomain, getLocale(context.locale))

    try {
      const baseArgs = {
        storeId: STORES[localeContext.toLowerCase()].storeId,
        langId: STORES[localeContext.toLowerCase()].langId,
      }

      const param = params?.join('/')
      const identifier = encodeURIComponent(`${localeContext?.toLowerCase()}/${param}`)

      const args = {
        ...baseArgs,
        identifier,
      }

      const seoData = await seoApiService.getSeo(args)
      if (seoData.contents.length === 0) {
        is404 = true
      }

      const seoDataContent = seoData.contents[0]

      const pageType = seoDataContent?.page?.type || SEO_PAGE_TYPES.NOT_FOUND

      const { categoryData, seoAltUrl, parentCatalogGroupID, product, breadcrumb } = await getPageData(
        {
          ...baseArgs,
          catalogId: STORES[localeContext.toLowerCase()].catalogId,
          identifier,
          brand: config?.storePrefix?.toUpperCase(),
        },
        pageType
      )

      const { pageData, siteDataRes, locale, header, megaMenu, footer, preFooter } = await fetchData({
        context,
        stateManager,
        pageType: is404 ? SEO_PAGE_TYPES.NOT_FOUND : product ? PAGE_TYPES.PRODUCT_PAGE : PAGE_TYPES.PRODUCT_LIST_PAGE,
        product,
        breadcrumb,
        searchParams: query,
        seoData: seoDataContent as unknown as ISeo,
        categoryData,
        isLocaleDomain,
      })

      const plpCommerce = product ? null : (pageData as ICommerceHclPage)
      const pdpCommerce = product ? (pageData as ICommerceHclPage) : null
      const pdpProduct = product ? fromServerToClientProduct(product) : null

      const plpState = is404
        ? ({} as Awaited<ReturnType<typeof getPlpProps>>)
        : await getPlpProps({
            seoData: seoDataContent,
            storeInfo: siteDataRes,
            searchParams: query,
            serverUrl,
            cookies,
            pageType,
            isLocaleDomain,
            locationOrigin,
            domainLocale: domainLocales,
          })

      if (plpState?.redirectUrl) {
        return redirectTo({ destination: plpState?.redirectUrl })
      }

      if (is404) {
        res.statusCode = 404
      } else {
        setHeaders(
          res,
          getHeaders({
            pageType,
            locale,
            pdpPartnumber: pdpProduct?.partNumber,
            plpIdentifier: seoDataContent.tokenValue,
          })
        )
      }
      const formattedSeoAltUrls = generateAlternateUrlMapping(seoAltUrl, protocol, hostname)

      return {
        props: {
          ...(await getServerSideStoreInfo(siteDataRes, locale)),
          seoData,
          seoAltUrl: formattedSeoAltUrls,
          categoryData: categoryData,
          parentCatalogGroupID,
          plpCommerce,
          pdpProduct,
          host,
          plpState,
          cookies,
          siteDataRes,
          preFooter,
          locale,
          pdpCommerce,
          header,
          footer,
          megaMenu,
          isLocaleDomain,
          locationOrigin,
          is404,
        },
      }
    } catch (e: any) {
      const destination = replaceLocaleInUrl({
        isLocaleDomain,
        locationOrigin,
        locale: context.locale,
        href: `/${context.locale}${NOT_FOUND}`,
        isRelative: true,
      }) as string
      logger.error(`smth went wrogn code: ${e.code || e.statusCode} message: ${e.message}`)
      logger.error(`smth went wrogn code: ${e.code || e.statusCode} message: ${e.stack}`)
      return redirectTo({ destination })
    }
  }
)

export default Page

const getPageData = async (args: PageDataArgs, pageType?: string) => {
  if (pageType === SEO_PAGE_TYPES.NOT_FOUND) {
    return {
      categoryData: null,
      seoAltUrl: null,
      parentCatalogGroupID: null,
      product: null,
      breadcrumb: null,
    }
  }

  if (pageType !== SEO_PAGE_TYPES.PRODUCT_PAGE && pageType !== SEO_PAGE_TYPES.ITEM_PAGE) {
    return await getPLPData(args)
  }

  return await getPdpData(args)
}

const getPLPData = async (args: PageDataArgs) => {
  const plpData = await pageApiService.getPLPData(args)
  return plpData
}

const getPdpData = async (args: PageDataArgs) => {
  const pdpData = await pageApiService.getPDPData(args)
  return pdpData
}

export const getServerUrl = (req: GetServerSidePropsContext['req']): string => {
  const protocol = req.headers.referer?.split('://')[0] || 'https'
  return `${protocol}://${req.headers.host}${req.url}`
}
