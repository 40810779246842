import { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import config from '@configs/index'
import { useSeoComponent } from '@foundation/hooks/useSeoComponent'
import ActiveComponent from '@foundation/seo/ActiveComponent'
import { siteSelector } from '@redux/selectors/site'
import Log from '@services/Log'
import { getServerLocale } from '@utils/locale'

export interface AlternateURL {
  key: string
  value: string
}

export interface AlternateURL {
  key: string
  value: string
}

const SEO: FC<any> = (props: any) => {
  const router = useRouter()
  const { seoData, seoAltUrl } = props
  const alternateSeoUrls: Record<string, string> = seoAltUrl
  const query = router.query as { params: string[] }
  const site = useSelector(siteSelector)
  Log.info('SEO Component - site used: ', JSON.stringify(site))
  const currentLangCode = getServerLocale(router.isLocaleDomain, router.locale as string)
  const url = `${currentLangCode.toLowerCase()}/${query.params.join('/')}`

  const brand = config?.storePrefix?.toUpperCase() as string
  Log.info('SEO Component - languageId ' + site?.langId)

  const { seoConfig } = useSeoComponent(seoData, brand)

  return (
    <ActiveComponent
      seoConfig={seoConfig}
      url={url}
      currentLangCode={currentLangCode}
      alternateSeoUrls={alternateSeoUrls}
      props={props}
    />
  )
}

export default memo(SEO)
